import React from "react"
import {Link as LinkBase} from "gatsby";
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import DbaasProviders from "./_dbaasProviders";

const ContentPage = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      refKey="dbaas"
      title="Automated DBaaS Backup Service"
      description="Automate your serverless databases, run them on your terms and store them on any cloud provider."

    />
    <Layout.Hero
      type="primary"
      title={(<>Automate & trust<br/>your <span className="text-primary">DbaaS backups</span></>)}
      subTitle={(
        <>Create automatic database backups for your managed/service database.<br/>
          Connect your DBaaS account, set a schedule, a storage and let it work in peace.
        </>)}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your database backup" urlAtrr={{ sb_source: "website", sb_term: "dbaas-hero" }} />)}
      illustration={(
          <Ui.Image className="" filename="simplebackups-dbaas-backup-hero.png" alt="Database as a service backup solution"/>
      )}
      withIllustrationBox={false}
    />

    <Ui.Container>
      <div className="md:mb-24 md:mt-28">
        <Sections.JoinBrandsSection />
      </div>
    </Ui.Container>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <div className="md:max-w-5xl m-auto">
            <Ui.Typography tag="h2" className="text-center">Backup MySQL, PostgreSQL, MongoDB & Redis managed databases</Ui.Typography>
            <p className="mb-6 text-center">
              Connect your database service provider and select the database you want to backup.<br/>
              We orchestrate and optimize database backups for the best DbaaS providers around, so you don't have to.
            </p>

            <div className="mt-16">
              <DbaasProviders></DbaasProviders>
            </div>

            <p className="text-center mt-16">
              <Ui.Link arrow="right" to="/features/#database-backups">Database Backups features</Ui.Link>
            </p>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "Control your backups",
                title: (<>Run your backups on <span className="text-primary">your own terms</span></>),
                description: (
                    <>
                      <p>
                        Schedule your backups when you need them to run, keep as many backups as you want and store them where you want.

                        <div className="mt-6"><Ui.Link arrow="right" to="#features">View all features</Ui.Link></div>
                      </p>
                    </>
                ),
                image: ( <Ui.Image
                    className="shadow-2xl rounded-xl"
                    filename='simplebackups-app-scheduling.png'
                    alt="Managed database backup"
                    style={{maxWidth: 450}}
                />)
              },
              Content_FeatureTrust(),
              Content_FeatureControl({subject: 'database'}),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt" id="features">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">Want to geek out on the technical details?</Ui.Typography>
          <div className="text-center">
            <p className="mb-6">SimpleBackups offers a user-friendly setup process, perfect for those who may not be tech experts. For the tech-savvy, we're eager to dive into the details, tailoring SimpleBackups to meet your unique requirements and maximize its potential for you.</p>
            <p className="py-5 text-lg font-bold">We support most DBaaS providers! Not in the list? Not a problem, just ask us!</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium">
              <li><i className="far fa-cloud text-green mr-2"></i>Amazon RDS</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Amazon Aurora</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Azure Database</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Aiven</li>
              <li><i className="far fa-cloud text-green mr-2"></i>cPanel MySQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>DigitalOcean Managed MySQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Google Cloud SQL MySQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Kamatera MySQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>MariaDB</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Percona Server</li>
              <li><i className="far fa-cloud text-green mr-2"></i>OVH MySQL/MariaDB</li>
              <li><i className="far fa-cloud text-green mr-2"></i>ScaleGrid Managed MySQL</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Neon</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Planetscale</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Railway</li>
              <li><i className="far fa-cloud text-green mr-2"></i>Supabase</li>
            </ul>

            <p className="mt-12 py-5 text-lg font-bold">Full-featured database Backup service, you’re covered:</p>
            <ul className="flex flex-wrap gap-x-8 gap-y-4 justify-center text-sm font-medium max-w-7xl">
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup all databases at once</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Select/Exclude tables</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Advanced database flags</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">SSL Support</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Backup streaming</span>
              </li>
              <li>
                <i className="far fa-check-circle text-green mr-2"></i>
                <span className="font-medium">Support for large database backups</span>
              </li>
            </ul>
            <div className="my-16 text-center"><Ui.Link arrow="right" to="/features">View all features</Ui.Link></div>

            <Ui.Typography tag="h3" className="text-2xl text-center mt-16 mb-8">Wait... there's even more...</Ui.Typography>
            <Sections.FeatureSlider tags={["mysql"]} />
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionLearn
      title={<>Wanna learn more about Database Backups?</>}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_is_dbaas_backups', 'what_is_dbaas', 'what_is_managed_database', 'can_download_backup', 'is_secure']}/>


  </Layout.Layout>
)

export default ContentPage
